import auditRoutes from "@/router/entities/audit";
import siteRoutes from "@/router/entities/site";
import therapistRoutes from "@/router/entities/therapist";
import siteManagerRoutes from "@/router/entities/siteManager";
import siteManagementRoutes from "@/router/entities/siteManagement";
import patientRoutes from "@/router/entities/patient";
import administratorRoutes from "@/router/entities/administrator";
import dataExportRoutes from "@/router/dataExportRoutes";
import { getSupportRoutes } from "@/router/supportRoutes";
import { getProfileRoutes } from "@/router/profileRoutes";

const routePrefix = "/dpo";

export default [
  {
    path: routePrefix,
    component: () => import("@/pages/DPOPages/DPOHomePage"),
    meta: {
      requiresDPO: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      ...getProfileRoutes(routePrefix),
      ...auditRoutes,
      ...siteRoutes,
      ...siteManagementRoutes,
      ...therapistRoutes,
      ...siteManagerRoutes,
      ...patientRoutes,
      ...administratorRoutes,
      ...dataExportRoutes,
      {
        path: "",
        name: "DPO/Home",
        component: () => import("@/pages/DPOPages/DPOHomePage/Dashboard"),
      },
    ],
  },
];
