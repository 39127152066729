import { Model } from "@vuex-orm/core";
import {
  parseISO,
  endOfMonth,
  startOfMonth,
  subDays,
  getDaysInMonth,
} from "date-fns";
import { format } from "@/utils/date";

export default class DayTrainingSummary extends Model {
  static entity = "dayTrainingSummary";
  static primaryKey = ["date", "patientUsername"];

  static fields() {
    return {
      date: this.attr(null),
      patientUsername: this.attr(null),
      hasPlan: this.attr(null),
      hasMissedPlan: this.attr(null),
      hasUnsupervisedSession: this.attr(null),
      hasSupervisedSession: this.attr(null),
      hasSessionSupervisedByCaller: this.attr(null),
    };
  }

  get Date() {
    return this.date ? parseISO(this.date) : null;
  }

  static async fetch(patientUsername, dateStart, dateEnd) {
    return this.api().get(
      `patient-performance/${patientUsername}/summary?from=${dateStart}&to=${dateEnd}`,
      { dataKey: "result" }
    );
  }

  /**
   * Fetches the patient training summary data for the requested month (+ nb days of previous month)
   * Used to display the training data in the performance timeline.
   * @param patientUsername
   * @param monthDate
   * @param nbDaysPreviousMonth
   * @return [DayTrainingSummary]
   */
  static async fetchForMonth(patientUsername, monthDate, nbDaysPreviousMonth) {
    let startingDay = format(
      subDays(startOfMonth(monthDate), nbDaysPreviousMonth),
      "yyyy-MM-dd"
    );
    let monthEnd = format(endOfMonth(monthDate), "yyyy-MM-dd");

    return this.fetch(patientUsername, startingDay, monthEnd);
  }

  /**
   * Returns the stored training summaries for the given month (+ nb days of previous month) and patient
   * @param patientUsername
   * @param monthDate
   * @param nbDaysPreviousMonth
   * @return [DayTrainingSummary]
   */
  static async getForMonth(patientUsername, monthDate, nbDaysPreviousMonth) {
    let startingDay = subDays(startOfMonth(monthDate), nbDaysPreviousMonth);
    return DayTrainingSummary.query()
      .where("patientUsername", patientUsername)
      .where(
        (record) =>
          startingDay <= record.Date && record.Date <= endOfMonth(monthDate)
      )
      .get();
  }

  /**
   * Gets the data for the given month (+ nb days of previous month) but fetches the data if not available or less entries than expected
   * This is used by both the timeline and the calendar selector for the performance report.
   */
  static getForMonthAndFetchIfNeeded(
    patientUsername,
    monthDate,
    nbDaysPreviousMonth
  ) {
    return this.getForMonth(
      patientUsername,
      monthDate,
      nbDaysPreviousMonth
    ).then((entries) => {
      if (
        !entries ||
        entries.length < getDaysInMonth(monthDate) + nbDaysPreviousMonth
      ) {
        return this.fetchForMonth(
          patientUsername,
          monthDate,
          nbDaysPreviousMonth
        ).then(() => {
          return this.getForMonth(
            patientUsername,
            monthDate,
            nbDaysPreviousMonth
          );
        });
      }
      return entries;
    });
  }
}
