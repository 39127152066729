// Wrapper used to easily set entity rules using casl management rules.

// The right types existing in Casl
const RightTypes = Object.freeze({
  Manage: "manage",
  Create: "create",
  Read: "read",
  Edit: "edit",
  Delete: "delete",
});

// Casl based ruleset usable as a bitmask
const Rights = Object.freeze({
  CanDoNothing: 0x00000,
  CanManage: 0x00001,
  CanCreate: 0x00010,
  CanRead: 0x00100,
  CanEdit: 0x01000,
  CanDelete: 0x10000,
  CanDoEverything: 0x11111,
});

/**
 * Set access rights over a given entity
 * @param abilityBuilder {AbilityBuilder} // Casl AbilityBuilder()
 * @param entity {string} // Database or Vuex-orm entity
 * @param access {Number} // Rights enum bitmask value
 */
const setEntityRules = (abilityBuilder, entity, access) => {
  const entityRuleSet = {
    // ruleName: true or false,
    [RightTypes.Manage]: !!(access & Rights.CanManage),
    [RightTypes.Create]: !!(access & Rights.CanCreate),
    [RightTypes.Read]: !!(access & Rights.CanRead),
    [RightTypes.Edit]: !!(access & Rights.CanEdit),
    [RightTypes.Delete]: !!(access & Rights.CanDelete),
  };

  for (let accessType in entityRuleSet) {
    let isAccessTypeGranted = entityRuleSet[accessType];

    if (isAccessTypeGranted) {
      abilityBuilder.can(accessType, entity);
    } else {
      abilityBuilder.cannot(accessType, entity);
    }
  }
};

export { setEntityRules, RightTypes, Rights };
