import i18n from "@/i18n";
import { getLanguageCodeFrom, defaultLocale } from "@/utils/locales";
import { getLanguage, setLanguage } from "@/utils/localStorage";

export default {
  namespaced: true,

  state: {
    /**
     * Stores the actual language.
     * Initializes it from the localStorage.
     * If there's none, we fall back to 'en-US'.
     */
    language: getLanguage() || defaultLocale,
  },

  mutations: {
    /**
     * Set language
     * @param {object} state Vuex state
     * @param {string} language
     */
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    setLanguage({ commit, getters }, language) {
      commit("setLanguage", language);
      setLanguage(language);
      const locale = getLanguageCodeFrom(language);
      i18n.changeLanguage(locale);
    },
    refreshLanguage({ commit, getters }) {
      const locale = getLanguageCodeFrom(getters.language);
      i18n.changeLanguage(locale);
    },
  },
  getters: {
    language(state) {
      return state.language;
    },
    isRTLLanguage(state) {
      return !!state.language?.startsWith("ar");
    },
    languageDirection(state, getters) {
      return getters.isRTLLanguage ? "rtl" : "ltr";
    },
  },
};
