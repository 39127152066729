import { Model } from "@vuex-orm/core";
import Profile from "@/models/Profile.model";
import { isEntityContainsAllTerms } from "@/utils/filters";
import { formatToMMIDWithDashes } from "@/utils/profile";

export default class PatientSessionSummary extends Model {
  static entity = "patientsessionsummaries";
  static primaryKey = "userName";

  static fields() {
    return {
      id: this.attr(null),
      userName: this.attr(null),
      firstName: this.attr(null),
      lastName: this.attr(null),
      therapistId: this.attr(null),
      isArchived: this.attr(null),
      lastSessionDate: this.attr(null),
      trend: this.attr("even"),
      email: this.attr("email"),
      MMID: this.attr(null),
      patientSiteId: this.attr(null),
      referenceId: this.attr(null),
      lastWeekActiveTrainingTime: this.attr(null),
      therapist: this.belongsTo(Profile, "therapistId"),
      patient: this.belongsTo(Profile, "userName", "userName"),
      adherence: this.attr(null),
      quality: this.attr(null),
      quantity: this.attr(null),
    };
  }

  get fullName() {
    if (!this.firstName && !this.lastName) {
      return null;
    }
    if (!this.firstName) {
      return this.lastName;
    }
    if (!this.lastName) {
      return this.firstName;
    }
    return this.firstName + " " + this.lastName;
  }

  /**
   *
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static get(signal) {
    return this.api().get("/session/concise", {
      dataKey: "result",
      signal,
    });
  }

  async archive() {
    return Profile.setArchiveState(this, true).then(() => {
      this.isArchived = true;
      this.$save();
    });
  }

  async reactivate() {
    return Profile.setArchiveState(this, false).then(() => {
      this.isArchived = false;
      this.$save();
    });
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    const entityFieldsToSearchInto = [
      this.firstName,
      this.lastName,
      this.patientSiteId,
      this.MMID,
      formatToMMIDWithDashes(this.MMID),
      this.email,
    ];

    return isEntityContainsAllTerms(entityFieldsToSearchInto, searchTerms);
  }
}
