import Vue from "vue";

const vueInterface = new Vue();

/**
 * Schedule a notification on the Vue UI
 * @param {string} type Either a 'success' or 'error'
 * @param {string} message The message to notify
 */
export const notify = (type, message) => {
  vueInterface.$notify({
    type: type,
    text: message,
    position: "top center",
  });
};
