import { AbilityBuilder, Ability } from "@casl/ability";
import { setEntityRules, Rights } from "@/config/entityRules.js";
import Store from "@/store";

function processRules() {
  const abilityBuilder = new AbilityBuilder();

  if (Store.getters["Auth/isSuperAdmin"]) {
    setEntityRules(
      abilityBuilder,
      "Customer",
      Rights.CanDoEverything & ~Rights.CanDelete
    );
    setEntityRules(
      abilityBuilder,
      "Site",
      Rights.CanDoEverything & ~Rights.CanDelete
    );
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "SiteUnitAssignment",
      Rights.CanDoEverything
    );
    setEntityRules(abilityBuilder, "License", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "PatientLicense", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "Asset", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "UserSession", Rights.CanRead);
    setEntityRules(
      abilityBuilder,
      "SiteManagerProfile",
      Rights.CanDoEverything
    );
    setEntityRules(abilityBuilder, "TherapistProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "AdminProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "DpoProfile", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "CustomerNotification",
      Rights.CanDoEverything
    );
    setEntityRules(
      abilityBuilder,
      "OptionalFeatureDefinition",
      Rights.CanDoEverything
    );
    setEntityRules(
      abilityBuilder,
      "OptionalFeatureActivation",
      Rights.CanDoEverything
    );
    setEntityRules(abilityBuilder, "FeedbackReport", Rights.CanRead);
    setEntityRules(abilityBuilder, "FeedbackReportAttachment", Rights.CanRead);
  }

  // default Admin without any competence
  if (Store.getters["Auth/isAdmin"]) {
    setEntityRules(abilityBuilder, "Customer", Rights.CanRead);
    setEntityRules(abilityBuilder, "Site", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnitAssignment", Rights.CanRead);
    setEntityRules(abilityBuilder, "License", Rights.CanRead);
    setEntityRules(abilityBuilder, "PatientLicense", Rights.CanRead);
    setEntityRules(abilityBuilder, "Asset", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteManagerProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "TherapistProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "AdminProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "DpoProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "OptionalFeatureDefinition", Rights.CanRead);
    setEntityRules(abilityBuilder, "OptionalFeatureActivation", Rights.CanRead);
    setEntityRules(abilityBuilder, "FeedbackReport", Rights.CanRead);
    setEntityRules(abilityBuilder, "FeedbackReportAttachment", Rights.CanRead);
  }
  if (Store.getters["Auth/isAdminManagerAdmin"]) {
    setEntityRules(abilityBuilder, "SiteManagerProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "TherapistProfile", Rights.CanRead);
    setEntityRules(abilityBuilder, "AdminProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "DpoProfile", Rights.CanDoEverything);
  }
  if (Store.getters["Auth/isAccountExecutiveAdmin"]) {
    setEntityRules(abilityBuilder, "Site", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "SiteUnitAssignment",
      Rights.CanDoEverything
    );
    setEntityRules(abilityBuilder, "Asset", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "SiteManagerProfile",
      Rights.CanDoEverything
    );
    setEntityRules(abilityBuilder, "TherapistProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "AdminProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "DpoProfile", Rights.CanDoEverything);
  }
  if (Store.getters["Auth/isDataScientistAdmin"]) {
    abilityBuilder.can("edit", "Site", ["kind"]);
  }
  if (Store.getters["Auth/isPublisherAdmin"]) {
    // right now can't do anything more than default admin
  }
  if (Store.getters["Auth/isDataExportManagerAdmin"]) {
    // right now can't do anything more than default admin
  }
  if (Store.getters["Auth/isOptionalFeatureManagerAdmin"]) {
    setEntityRules(
      abilityBuilder,
      "OptionalFeatureActivation",
      Rights.CanDoEverything
    );
  }
  if (Store.getters["Auth/isSalesperson"]) {
    setEntityRules(abilityBuilder, "Customer", Rights.CanManage);
    setEntityRules(abilityBuilder, "Asset", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "SiteManagerProfile",
      Rights.CanDoEverything
    );
  }
  if (Store.getters["Auth/isDPO"]) {
    setEntityRules(abilityBuilder, "AuditLog", Rights.CanRead);
    setEntityRules(abilityBuilder, "Site", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnitAssignment", Rights.CanRead);
    setEntityRules(
      abilityBuilder,
      "SiteManagerProfile",
      Rights.CanDoEverything & ~Rights.CanEdit & ~Rights.CanCreate
    );
    setEntityRules(
      abilityBuilder,
      "TherapistProfile",
      Rights.CanDoEverything & ~Rights.CanEdit & ~Rights.CanCreate
    );
    setEntityRules(abilityBuilder, "AdminProfile", Rights.CanDoEverything);
    abilityBuilder.cannot("edit", "AdminProfile", ["email"]);
    setEntityRules(
      abilityBuilder,
      "DpoProfile",
      Rights.CanDoEverything & ~Rights.CanEdit
    );
    setEntityRules(
      abilityBuilder,
      "PatientProfile",
      Rights.CanDoEverything & ~Rights.CanEdit & ~Rights.CanCreate
    );
    setEntityRules(abilityBuilder, "OptionalFeatureDefinition", Rights.CanRead);
    setEntityRules(abilityBuilder, "OptionalFeatureActivation", Rights.CanRead);
  }
  if (Store.getters["Auth/isSiteManager"]) {
    setEntityRules(abilityBuilder, "Site", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanDoEverything);
    setEntityRules(
      abilityBuilder,
      "SiteUnitAssignment",
      Rights.CanDoEverything
    );
    abilityBuilder.can("edit", "Site", ["passwordParameters"]);
    abilityBuilder.can("edit", "Site", ["timeZoneId"]);
    abilityBuilder.can("edit", "Site", ["samlParameters"]);
  }
  if (Store.getters["Auth/isTherapist"]) {
    setEntityRules(abilityBuilder, "PatientProfile", Rights.CanDoEverything);
    setEntityRules(abilityBuilder, "Site", Rights.CanRead);
    setEntityRules(
      abilityBuilder,
      "VideoMeeting",
      Rights.CanDoEverything & ~Rights.CanManage
    );
    setEntityRules(abilityBuilder, "SiteUnit", Rights.CanRead);
    setEntityRules(abilityBuilder, "SiteUnitAssignment", Rights.CanRead);

    setEntityRules(abilityBuilder, "UserSession", Rights.CanCreate);
    if (Store.getters["Auth/isSuperTherapist"]) {
      setEntityRules(
        abilityBuilder,
        "SiteUnit",
        Rights.CanRead | Rights.CanEdit
      );
      setEntityRules(
        abilityBuilder,
        "SiteUnitAssignment",
        Rights.CanDoEverything
      );
      abilityBuilder.cannot("edit", "Site", ["passwordParameters"]);
      abilityBuilder.can("edit", "Site", ["timeZoneId"]);
      // N.B: SAML Parameters edition depends also on the site.samlParameters.canSupertherapistAdministerSAML setting.
      abilityBuilder.can("edit", "Site", ["samlParameters"]);
    }
  }
  if (Store.getters["Auth/isPatient"]) {
    setEntityRules(abilityBuilder, "VideoMeeting", Rights.CanRead);
    setEntityRules(abilityBuilder, "UserSession", Rights.CanCreate);
  }

  return abilityBuilder.rules;
}

let ability = new Ability();

const updateUserAccessRights = () => {
  if (ability) {
    ability.update(processRules());
  }
};

export { ability, updateUserAccessRights };
