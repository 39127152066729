<!--
  Global application page
-->
<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script>
// Components
import AppLayout from "@/components/layout/AppLayout";

// Libraries, services
import { mapActions, mapGetters } from "vuex";

/**
 * https://github.com/vuejs/vue-cli/issues/7431#issuecomment-1793385162
 * Stop error "ResizeObserver loop completed with undelivered notifications".
 */
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

export default {
  components: {
    AppLayout,
  },
  created() {
    // Reload the current locale when the storage has a different one that the default one.
    this.refreshLanguage();
    this.updateHtmlDirAttribute();
  },
  computed: {
    ...mapGetters("Language", ["languageDirection"]),
  },
  methods: {
    ...mapActions("Language", ["refreshLanguage"]),
    updateHtmlDirAttribute() {
      const htmlTag = document.querySelector("html");
      if (htmlTag) {
        htmlTag.setAttribute("dir", this.languageDirection);
      }
    },
  },
  watch: {
    languageDirection() {
      this.updateHtmlDirAttribute();
    },
  },
};
</script>
