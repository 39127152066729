export default [
  {
    path: "/zoom/activate",
    name: "ZoomActivate",
    component: () => import("@/pages/CommonPages/ZoomPages/ZoomActivate.vue"),
  },
  {
    path: "/zoom/documentation",
    name: "ZoomDocumentation",
    component: () =>
      import("@/pages/CommonPages/ZoomPages/ZoomDocumentation.vue"),
  },
];
