<template>
  <form id="login-form" autocomplete="on" class="form-group">
    <div class="mx-12">
      <mindmaze-input
        id="login"
        :label="$t('LoginPage.loginLabel')"
        :placeholder="$t('LoginPage.loginPlaceholder')"
        v-model="$v.login.$model"
        @animationstart="loginAnimationStart"
        @focusout="updateLoginInputErrors"
        @input="resetLoginInputErrors"
        :any-error="loginErrors.length > 0"
      />
      <input-error :errors="loginErrors" />
      <password-input
        id="login-password"
        v-model="$v.password.$model"
        :label="$t('LoginPage.password')"
        :placeholder="$t('LoginPage.passwordPlaceholder')"
        @animationstart="passwordAnimationStart"
      />
      <base-button
        :isButtonType="false"
        class="mx-16 mt-6 flex items-center justify-center"
      >
        <input
          type="submit"
          class="btn btn-primary text-white bg-blue-500 h-full w-full"
          @click.prevent="doLogin"
          :value="$t('LoginPage.loginButton')"
          :disabled="isLoginButtonDisabled"
        />
      </base-button>
    </div>
  </form>
</template>

<script>
import PasswordInput from "@/components/ui/PasswordInput";
import MindmazeInput from "@/components/ui/BaseInput";
import InputError from "@/components/ui/InputError";
import { login } from "@/services/auth.js";
import { email, required } from "vuelidate/lib/validators";
import { getErrorMessageFrom } from "@/services/api.js";
import { updateUserAccessRights } from "@/config/ability";
import AuthOptions from "@/models/AuthOptions.model";

export default {
  beforeMount() {
    AuthOptions.get();
  },
  components: {
    PasswordInput,
    MindmazeInput,
    InputError,
  },
  data() {
    return {
      login: "",
      password: "",
      isLoading: false,
      loginErrors: [],
      // Indicates if the username was filled by autofill
      loginAutofill: false,
      // Indicates if the password was filled by autofill
      passwordAutofill: false,
      loginAttemptsCount: 0,
      loggingTimeoutIds: -1,
    };
  },
  validations: {
    login: {
      required,
      login(value) {
        return /^[a-zA-Z0-9_-]*$/.test(value) || email(value);
      },
    },
    password: {
      required,
    },
  },
  computed: {
    lockoutOptions() {
      return AuthOptions.query().first()?.lockout;
    },
    isLoginButtonDisabled() {
      // The login button should be disabled if there is no username and password and if both values were not autofilled
      return (
        ((!this.login && !this.loginAutofill) ||
          (!this.password && !this.passwordAutofill)) &&
        !this.isLoading
      );
    },
  },
  mounted() {
    // Fix for Chrome credential manager triggering the auto fill events without informing Vue every time.
    // This is why the code below is pure html5/js not using reference or anything related to the virtual DOM.
    // We must wait for it to trigger the auto-fill cancel and then the autofill-start for this to work, hence the timeout.
    this.loggingTimeoutIds = setTimeout(() => {
      let loginElement = document
        .getElementById("login")
        .getElementsByTagName("input")[0];
      if (getComputedStyle(loginElement).animationName === "onAutoFillStart") {
        this.loginAutofill = true;
      }
      loginElement = document
        .getElementById("login-password")
        .getElementsByTagName("input")[0];
      if (getComputedStyle(loginElement).animationName === "onAutoFillStart") {
        this.passwordAutofill = true;
      }
    }, 1000);
  },
  beforeDestroy() {
    // Clear timeout triggers that otherwise stay on the window worker
    clearTimeout(this.loggingTimeoutIds);
  },
  methods: {
    async doLogin() {
      this.isLoading = true;
      this.loginAttemptsCount++;
      await login(this.login, this.password)
        .then(() => {
          updateUserAccessRights();
          this.$emit("logged");
        })
        .catch((error) => {
          let errorMessage = getErrorMessageFrom(
            error,
            this.$t("errors.login.loginError")
          );
          let lockoutWarningMessage = "";

          // notify about lockout error
          const lockoutErrorKey = "errors.login.lockedOut";
          if (error?.response?.data?.errors.includes(lockoutErrorKey)) {
            errorMessage = this.$t("LoginPage.lockout", {
              lockOutMinutes: this.lockoutOptions.defaultLockoutTimeSpan,
            });
          } else if (
            this.lockoutOptions?.enabled &&
            this.loginAttemptsCount >=
              this.lockoutOptions.maxFailedAccessAttempts - 2
          ) {
            lockoutWarningMessage = this.$t("LoginPage.accessAttemptsLeft", {
              maxLoginAttempts: this.lockoutOptions.maxFailedAccessAttempts,
              lockOutMinutes: this.lockoutOptions.defaultLockoutTimeSpan,
            });
          }
          this.$emit("loginError", errorMessage, lockoutWarningMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // If the onAutofillStart animation started we want to set usernameAutofill to true
    loginAnimationStart(event) {
      if (event.animationName === "onAutoFillStart") {
        this.loginAutofill = true;
      }
    },
    // If the onAutofillStart animation started we want to set passwordAutofill to true
    passwordAnimationStart(event) {
      if (event.animationName === "onAutoFillStart") {
        this.passwordAutofill = true;
      }
    },
    updateLoginInputErrors() {
      this.loginErrors = [];
      if (!this.$v.login.login || !this.$v.login.required) {
        this.loginErrors.push(this.$t("errors.login.requireValidLogin"));
      }
    },
    resetLoginInputErrors() {
      this.loginErrors = [];
    },
  },
  watch: {
    login() {
      this.loginAutofill = false;
    },
    password() {
      this.passwordAutofill = false;
    },
  },
};
</script>

<style>
/*
  Fix for Chrome autofill issues, see: https://rojas.io/how-to-detect-chrome-autofill-with-vue-js/
  TL;DR: Adding empty animation events permit to get animation events in Vue more reliably.
  This style must unfortunately be declared as non scoped.
 */
input:-webkit-autofill {
  animation-name: onAutoFillStart;
}
input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
