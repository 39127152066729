import axios from "axios";
import store from "@/store/";
import { openLoginModal } from "@/components/login/LoginModal";
import { notify } from "@/utils/notification";
import { tr } from "@/utils/translation";
import Customer from "@/models/Customer.model";
import License from "@/models/License.model";
import PatientLicense from "@/models/PatientLicense.model";
import Site from "@/models/Site.model";
import SiteUnit from "@/models/SiteUnit.model";
import Timezone from "@/models/Timezone.model";
import ActivityDescription from "@/models/ActivityDescription.model";
import Asset from "@/models/Asset.model";
import UserSession from "@/models/UserSession.model";
import Profile from "@/models/Profile.model";
import PersonalSettings from "@/models/PersonalSettings.model";
import Group from "@/models/Group.model";
import AuditLog from "@/models/AuditLog.model";
import VideoMeeting from "@/models/VideoMeeting.model";
import VideoMeetingRequest from "@/models/VideoMeetingRequest.model";
import PatientSessionSummary from "@/models/PatientSessionSummary.model";
import { ProgramPreset } from "@/models/ProgramPreset.model";
import DayTrainingSummary from "@/models/DayTrainingSummary.model";
import CustomerNotification from "@/models/CustomerNotification.model";
import InstructionsForUse from "@/models/InstructionsForUse.model";
import AuthOptions from "@/models/AuthOptions.model";
import PasswordOptions from "@/models/PasswordOptions.model";
import ActivityPatientUsage from "@/models/ActivityPatientUsage.model";
import OptionalFeatureDefinition from "@/models/OptionalFeatureDefinition.model";
import OptionalFeatureActivation from "@/models/OptionalFeatureActivation.model";
import TrainingMaterial from "@/models/training-material/TrainingMaterial.model";
import TherapeuticGoal from "@/models/TherapeuticGoal.model";
import LevelRecommendation from "@/models/LevelRecommendation.model";
import FeedbackReport from "@/models/FeedbackReport.model";
import FeedbackReportAttachment from "@/models/FeedbackReportAttachment.model";
import { updateUserAccessRights } from "@/config/ability";
import { requestNewAuthToken } from "@/services/auth";
import { getAuthTokenCookie } from "@/utils/cookies";
import { getDeviceId, getRefreshToken } from "@/utils/localStorage";

const loginInterceptor = (config) => {
  const token = store.state.Auth.token || getAuthTokenCookie();
  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

// Common promise used to refresh the auth token using the refresh token
let refreshTokenPromise = null; // null | Promise<any>

/**
 * Rejected request interceptor, used to trigger a refresh token call when needed.
 * And display the login modal otherwise
 * @param error
 * @param axiosInstance
 * @returns {*} the response or a promise waiting for the refresh before the response
 */
const unauthorizedInterceptor = (error, axiosInstance) => {
  const response = error.response;

  // Unauthorized interception
  if (response && response.status === 401) {
    // Don't refresh on a refresh attempt failure
    if (response.config?.url === "/auth/refresh") {
      return displayLoginModal(error);
    }

    // Check the data is there to request a new token
    const token = store.state.Auth.token || getAuthTokenCookie();
    const refreshToken = store.state.Auth.refreshToken || getRefreshToken();
    const deviceId = store.state.Auth.deviceId || getDeviceId();

    // If any data is missing, the request cannot be fulfilled anyway
    if (!token || !refreshToken || !deviceId) {
      return displayLoginModal(error);
    }

    if (!refreshTokenPromise) {
      refreshTokenPromise = requestNewAuthToken(token, refreshToken, deviceId)
        .catch(() => {
          // Empty the refresh token data as it is invalid
          refreshTokenPromise = null;
          return store.dispatch("Auth/setRefreshToken", "");
        })
        .then((response) => {
          // Update refresh token promise and date
          refreshTokenPromise = null;

          const result = response?.data?.result || {};
          const newToken = result.token;
          const newRefreshToken = result.refreshToken;

          return Promise.allSettled([
            store.dispatch("Auth/setToken", newToken),
            store.dispatch("Auth/setRefreshToken", newRefreshToken),
          ]).then(() => {
            return newToken;
          });
        });
    }

    return refreshTokenPromise.then((token) => {
      response.config.headers["Authorization"] = `Bearer ${token}`;
      return axiosInstance.request(response.config);
    });
  }

  // Notify of a back-end system error
  if (!error || !error.response || error.response.status >= 500) {
    notifyError(error);
  }

  return Promise.reject(error);
};

/**
 * Display the login modal (or stay on the login screen) in an attempt to restore the login credentials
 * @param error Request rejected error
 * @returns {*}
 */
const displayLoginModal = (error) => {
  // Update user right due to token expiration
  updateUserAccessRights();

  // If already on the login screen, don't do anything else
  // than checking the error
  if (error.config.url.includes("auth/token")) {
    return Promise.reject(error);
  }

  return openLoginModal().then((token) => {
    error.response.config.headers["Authorization"] = `Bearer ${token}`;
    return axios.request(error.config);
  });
};

// We use this api to avoid having the error interceptor when persisting login
const userLoginApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_USER_API}`,
});

// We use a specific service for token refresh to avoid looping on interceptors
const userRefreshTokenApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_USER_API}`,
});

// Standard rest api services

const userApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_USER_API}`,
});

userApi.interceptors.request.use(loginInterceptor);
userApi.interceptors.response.use(
  (response) => response,
  (error) => unauthorizedInterceptor(error, userApi)
);

const activityApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_ACTIVITY_API}`,
});

activityApi.interceptors.request.use(loginInterceptor);
activityApi.interceptors.response.use(
  (response) => response,
  (error) => unauthorizedInterceptor(error, activityApi)
);

const licenseApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_LICENSE_API}`,
});

licenseApi.interceptors.request.use(loginInterceptor);
licenseApi.interceptors.response.use(
  (response) => response,
  (error) => unauthorizedInterceptor(error, licenseApi)
);

const notificationApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_NOTIFICATION_API}`,
});

notificationApi.interceptors.request.use(loginInterceptor);
notificationApi.interceptors.response.use(
  (response) => response,
  (error) => unauthorizedInterceptor(error, notificationApi)
);

const auditApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_AUDIT_API}`,
});

auditApi.interceptors.request.use(loginInterceptor);
auditApi.interceptors.response.use(
  (response) => response,
  (error) => unauthorizedInterceptor(error, auditApi)
);

/**
 * @description Notify the translated response first message from the provided axios response.
 * If the message translation corresponding is not found, it triggers a default success translation.
 * @param {object} response The axios response object, typically used on then()
 */
const notifySuccess = (response) => {
  // Set a default message in case none is found.
  let message = "";

  if (
    response.data &&
    response.data.messages &&
    response.data.messages.length > 0
  ) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // Set a specific message when found
    let firstValue = tr(response.data.messages[0]);
    message = firstValue ? firstValue : message;
  } else if (
    response.data &&
    response.data.errors &&
    response.data.errors.length > 0
  ) {
    // Show response.data.errors as warnings for development builds
    if (process.env.NODE_ENV === "development") {
      message = response.data.errors;
    }
  }
  // Show it to the user if any message to show
  if (message) {
    notify("success", message);
  }
};

/**
 * @description Notify about the errors from the axios response.
 * @param {object} response The axios response object, typically used on then()
 */
const notifyWarning = (response) => {
  if (
    response.data &&
    response.data.errors &&
    response.data.errors.length > 0
  ) {
    for (let error of response.data.errors) {
      notify("warning", tr(error));
    }
  }
};

/**
 * Extract a error message from the provided axios error and return it.
 * Depending on the error state, the correct error inner data is used.
 * If the error translation corresponding to inner data is not found, it uses the provided defaultMessage.
 * @param {object} error The axios error object, typically created on catch()
 * @param {String} defaultMessage
 */
const getErrorMessageFrom = (error, defaultMessage = "") => {
  // Set a default message in case none is found.
  let message =
    defaultMessage === "" ? tr("errors.unknownError") : defaultMessage;

  if (error && !error.response) {
    // Network error
    message = tr("errors.system.networkError");
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // Set a specific message when found and attempt to translate it
    // Concatenate every error messages
    if (error.response.data && error.response.data.errors) {
      const translatedErrors = [];
      const untranslatedErrors = [];
      error.response.data.errors.forEach((error) => {
        // translate falling back to default language and then to empty string
        const translatedError = tr([error, ""]);
        if (translatedError) {
          translatedErrors.push(translatedError);
        } else {
          untranslatedErrors.push(error);
        }
      });
      // if there is at least one translated error, we show only them
      // otherwise we show the untranslated errors
      if (translatedErrors.length > 0) {
        message = translatedErrors.join("\n");
      } else {
        message = untranslatedErrors.join("\n");
      }
      // Add information for development builds
      if (process.env.NODE_ENV === "development") {
        console.log("translated errors", translatedErrors);
        console.log("untranslated errors", untranslatedErrors);
      }
    }

    // Add information for development builds
    if (process.env.NODE_ENV === "development") {
      console.log("Errors: ", error.response);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    // Add information for 4xx and 5xx responses for development builds
    if (process.env.NODE_ENV === "development") {
      console.log(
        "The request was made but something went wrong: ",
        error.request
      );
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // Add information for 4xx and 5xx responses for development builds
    if (process.env.NODE_ENV === "development") {
      console.log("An error occurred when setting up the request: ", error);
    }
  }
  // Show it to the user
  return message;
};

const getErrorKeysFrom = (error) => {
  return error.response.data?.errors || [];
};

/**
 * @description Notify the translated error message from the provided axios error, if not of Cancel type.
 * @param {object} error The axios error object, typically created on catch()
 */
const notifyError = (error) => {
  if (!axios.isCancel(error)) {
    notify("error", getErrorMessageFrom(error));
  }
};

/**
 * Provides the request result when available, and use the error to notify the UI in case of error, rejecting the promise.
 * This is the typical behavior of the Companion backend requests and should only be used for those.
 * @param promise
 * @returns {Promise<*>} when successful
 */
const processRequestResult = async (promise, notifyOnSuccess = false) => {
  return await promise.then(
    (response) => {
      if (notifyOnSuccess) {
        notifySuccess(response);
      }
      return response.data.result;
    },
    (error) => {
      notifyError(error);
      return Promise.reject(error);
    }
  );
};

License.setAxios(licenseApi);
PatientLicense.setAxios(licenseApi);
Site.setAxios(userApi);
SiteUnit.setAxios(userApi);
Timezone.setAxios(userApi);
ActivityDescription.setAxios(activityApi);
ActivityPatientUsage.setAxios(activityApi);
Asset.setAxios(licenseApi);
UserSession.setAxios(userApi);
Customer.setAxios(licenseApi);
Profile.setAxios(userApi);
PersonalSettings.setAxios(userApi);
AuditLog.setAxios(auditApi);
VideoMeeting.setAxios(userApi);
VideoMeetingRequest.setAxios(userApi);
PatientSessionSummary.setAxios(activityApi);
ProgramPreset.setAxios(activityApi);
DayTrainingSummary.setAxios(activityApi);
CustomerNotification.setAxios(userApi);
InstructionsForUse.setAxios(licenseApi);
AuthOptions.setAxios(userApi);
PasswordOptions.setAxios(userApi);
Group.setAxios(userApi);
OptionalFeatureDefinition.setAxios(licenseApi);
OptionalFeatureActivation.setAxios(licenseApi);
TrainingMaterial.setAxios(licenseApi);
TherapeuticGoal.setAxios(activityApi);
LevelRecommendation.setAxios(activityApi);
FeedbackReport.setAxios(userApi);
FeedbackReportAttachment.setAxios(userApi);

export {
  userApi,
  activityApi,
  licenseApi,
  notificationApi,
  auditApi,
  userLoginApi,
  userRefreshTokenApi,
  getErrorMessageFrom,
  getErrorKeysFrom,
  notifyError,
  notifySuccess,
  notifyWarning,
  processRequestResult,
};
