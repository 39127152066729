// Data stored in the local storage

const refreshTokenStorageName = "mm_refresh_token";
const deviceIdStorageName = "mm_device_id";
const languageStorageName = "mm_language";

// Language
export function setLanguage(language) {
  return window.localStorage.setItem(languageStorageName, language);
}

export function getLanguage() {
  return window.localStorage.getItem(languageStorageName);
}

// Webapp Device ID
export function setDeviceId(deviceId) {
  window.localStorage.setItem(deviceIdStorageName, deviceId);
}

export function getDeviceId() {
  return window.localStorage.getItem(deviceIdStorageName);
}

// Refresh token
export function setRefreshToken(refreshToken) {
  window.localStorage.setItem(refreshTokenStorageName, refreshToken);
}

export function getRefreshToken() {
  return window.localStorage.getItem(refreshTokenStorageName);
}

export function removeRefreshToken() {
  window.localStorage.removeItem(refreshTokenStorageName);
}
