import Vue from "vue";
import vuelidateErrorExtractor from "vuelidate-error-extractor";

import VuelidateErrorTemplate from "@/components/ui/VuelidateErrorTemplate";

Vue.use(vuelidateErrorExtractor, {
  messages: {
    required: "Common.Validators.Fields.{attribute}.required",
    minLength: "Common.Validators.Fields.{attribute}.minLength",
    email: "Common.Validators.Fields.{attribute}.email",
    minValue: "Common.Validators.Fields.{attribute}.minValue",
    mustBeUnique: "Common.Validators.Fields.{attribute}.mustBeUnique",
    sameAs: "Common.Validators.Fields.{attribute}.sameAs",
  },
  template: VuelidateErrorTemplate,
});
