<template>
  <stack
    class="w-full min-h-32 justify-center items-center align-middle text-center bg-white"
  >
    <slot name="text">
      <span class="font-semibold mb-2">
        {{ $t("Common.System.loading") }}
      </span>
    </slot>
    <slot name="icon">
      <svg-icon name="loading" class="loading-icon icon-6xl" />
    </slot>
  </stack>
</template>

<script>
import Stack from "@/components/layout/common/Stack";
export default {
  components: { Stack },
};
</script>

<style type="postcss" scoped>
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loading-icon {
  animation: rotate 2s infinite linear;
}
</style>
