import { Model } from "@vuex-orm/core";
import DateTimeOffset from "@/models/DateTimeOffset.model";
import Profile from "@/models/Profile.model";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export default class FeedbackReport extends Model {
  static entity = "feedbackReport";

  static salesForceGenericUrl;

  static fields() {
    return {
      id: this.attr(null),
      createdAtUtc: this.attr(null),
      version: this.attr(null),
      username: this.attr(null),
      reporterName: this.attr(null),
      email: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      type: this.attr(null),
      productName: this.attr(null),
      productVersion: this.attr(null),
      details: this.attr(null),
      salesforceSupportCaseId: this.attr(null),
      userProfile: this.belongsTo(Profile, "username", "userName"),
    };
  }

  get ProductInfo() {
    if (this.productName && this.productVersion) {
      return `${this.productName} ${this.productVersion}`;
    }

    if (this.productName) {
      return this.productName;
    }

    if (this.productVersion) {
      return this.productVersion;
    }

    return "";
  }

  get ReporterInfo() {
    if (this.reporterName && this.email) {
      return `${this.reporterName} ${this.email}`;
    }

    if (this.reporterName) {
      return this.reporterName;
    }

    if (this.email) {
      return this.email;
    }

    return "";
  }

  get CreatedDateAtUtc() {
    return new DateTimeOffset(this.createdAtUtc);
  }

  get DetailsSerialized() {
    return JSON.stringify(this.details, null, 2);
  }

  get salesforceSupportCaseUrl() {
    if (FeedbackReport.salesForceGenericUrl && this.salesforceSupportCaseId) {
      return FeedbackReport.salesForceGenericUrl.replace(
        "{0}",
        this.salesforceSupportCaseId
      );
    } else {
      return "";
    }
  }

  /**
   *   Get Salesforce generic URL.
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchSalesforceGenericUrl(signal) {
    return await this.api()
      .get("/feedbackreport/sfdc-url", { signal })
      .then((response) => {
        return (FeedbackReport.salesForceGenericUrl = response.response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *   Fetch all feedback report from companion.
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchAllFeedbackReports(signal) {
    return await this.api()
      .get("/feedbackreport", { dataKey: "result", signal })
      .then(() => {
        return FeedbackReport.query().all();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *   Returns the feedback report from the store or fetch it from companion.
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async getAllFeedbackReports(signal) {
    let reports = FeedbackReport.query().all();
    if (reports && reports.length > 0) {
      return reports;
    } else {
      return await this.fetchAllFeedbackReports(signal);
    }
  }

  /**
   *   Create feedback report on Salesforce.
   *   @param {FeedbackReport} feedbackReport Permits request cancellation support
   */
  static async postFeedbackReportOnSalesforce(feedbackReport) {
    return await this.api()
      .post("/feedbackreport/" + feedbackReport.id + "/salesforce", undefined, {
        dataKey: "result",
      })
      .then(() => {
        return FeedbackReport.query().find(feedbackReport.id);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.type, this.id];
    if (this.ReporterInfo) {
      fields.push(tidySearchTerm(this.ReporterInfo));
    }
    if (this.ProductInfo) {
      fields.push(tidySearchTerm(this.ProductInfo));
    }
    if (this.title) {
      fields.push(tidySearchTerm(this.title));
    }
    if (this.salesforceSupportCaseId) {
      fields.push(this.salesforceSupportCaseId);
    }
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
