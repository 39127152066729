import { Model } from "@vuex-orm/core";
import Site from "@/models/Site.model";
import store from "@/store";
import { buildNameFromFirstAndLastName } from "@/utils/profile";
import { deserializeConsent } from "@/utils/signedDocuments";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";
import { defaultLocale } from "@/utils/locales";
import {
  THERAPIST_ROLE,
  SITEMANAGER_ROLE,
  ADMIN_ROLE,
  DPO_ROLE,
  PATIENT_ROLE,
} from "@/services/auth.js";
import { tr } from "@/utils/translation";

function queryableEndpoints() {
  const isAdmin = store.getters["Auth/isAdmin"];
  const isSuperAdmin = store.getters["Auth/isSuperAdmin"];
  const isDPO = store.getters["Auth/isDPO"];
  const isAdminManagerAdmin = store.getters["Auth/isAdminManagerAdmin"];
  const isSiteManager = store.getters["Auth/isSiteManager"];
  const isSuperTherapist = store.getters["Auth/isSuperTherapist"];
  const isTherapist = store.getters["Auth/isTherapist"];

  let endpoints = [];
  if (isSuperAdmin || isDPO || isAdminManagerAdmin) {
    endpoints.push("/profile/admin");
  }
  if (isSuperAdmin || isDPO) {
    endpoints.push("/profile/dpoadmin");
  }
  if (isDPO || isAdmin || isSuperAdmin || isSiteManager || isSuperTherapist) {
    endpoints.push("/profile/sitemanager");
  }
  if (isDPO || isAdmin || isSuperAdmin || isTherapist || isSiteManager) {
    endpoints.push("/profile/therapist");
  }
  if (isSuperAdmin || isDPO || isTherapist) {
    endpoints.push("/profile/patient");
  }
  return endpoints;
}

export default class Profile extends Model {
  static entity = "profiles";
  static primaryKey = "userName";

  static fields() {
    return {
      id: this.attr(null),
      userName: this.attr(null),
      firstName: this.attr(null),
      lastName: this.attr(null),
      email: this.attr(null),
      roles: this.attr([]),
      competences: this.attr([]),
      isSuperTherapist: this.attr(false),
      phoneNumber: this.attr(null),
      gender: this.attr(null),
      birthDate: this.attr(null),
      therapistId: this.attr(null),
      patientSiteId: this.attr(null),
      passwordChangeRequired: this.attr(null),
      MMID: this.attr(null),
      emailConfirmed: this.attr(false),
      isAMindMotionPatient: this.attr(false),
      siteId: this.attr(null),
      referenceId: this.attr(null),
      condition: this.attr(null),
      leftHandImpairment: this.attr(null),
      rightHandImpairment: this.attr(null),
      additionalConditionInformation: this.attr(null),
      language: this.attr(defaultLocale),
      isEULASignatureRequired: this.attr(false),
      isPrivacyPolicySignatureRequired: this.attr(false),
      isConsentFormSignatureRequired: this.attr(false),
      enforceSAMLLogin: this.attr(false),
      samlLoginId: this.attr(null),
      signedDocuments: this.attr([]),
      site: this.belongsTo(Site, "sideId"),
      therapist: this.belongsTo(Profile, "therapistId"),
      lastDismissedCustomerNotificationVersion: this.attr("0.0.0"),
      timeZoneId: this.attr(null),
    };
  }

  static mutators() {
    return {
      // Handle depreciated DB values by ensuring the key to start with lowercase letter
      condition(value) {
        let key = null;
        if (value) {
          key =
            value.trim().charAt(0).toLowerCase() + value.trim().substring(1);
          if (key.includes("parkinson")) {
            key = "parkinsonDisease";
          }
        }
        return key;
      },
    };
  }

  /**
   * Displays a proper displayed name depending on the available profile data
   */
  get displayedName() {
    if (this.fullName?.length) {
      return this.fullName;
    } else if (this.isPatient && this.patientSiteId?.trim().length) {
      return this.patientSiteId;
    } else if (this.email) {
      return this.email;
    } else {
      // Gives the role
      return tr("Common.Profile.Roles." + this.roles[0]);
    }
  }

  /**
   * Displays name with initial of firstname and then lastname (with fallback on displayedName)
   * ex: F. Lastname
   */
  get shortName() {
    if (this.firstName?.trim().length && this.lastName?.trim().length) {
      return this.initialOfFirstNameAndLastName;
    }
    return this.displayedName;
  }
  //returns email if exists, otherwise returns username
  //this is needed for authentication sorting
  get authenticationName() {
    return this.email ? this.email : this.userName;
  }

  get fullName() {
    return buildNameFromFirstAndLastName(this.firstName, this.lastName);
  }

  get fullNameHtml() {
    return buildNameFromFirstAndLastName(this.firstName, this.lastName, true);
  }

  get initialOfFirstNameAndLastName() {
    if (!this.firstName?.trim().length || !this.lastName?.trim().length) {
      return null;
    }
    return `${this.firstName[0]}. ${this.lastName}`;
  }

  get use12HourTimeFormat() {
    return this.language === defaultLocale;
  }

  get consents() {
    return deserializeConsent(this.signedDocuments);
  }

  // Fetch API
  static get() {
    return Promise.all(
      queryableEndpoints().map(
        async (endpoint) =>
          await this.api().get(endpoint, { dataKey: "result" })
      )
    );
  }

  static getById(id) {
    return this.api().get("/profile/id/" + id, { dataKey: "result" });
  }

  /**
   * Fetch my profile from companion.
   */
  static async fetchMyProfile() {
    return await this.api().get("/profile", { dataKey: "result" });
  }

  /**
   * Returns all patient profiles from the current user site
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getPatients(signal) {
    return await this.api().get("profile/patient", {
      dataKey: "result",
      signal,
    });
  }

  // Returns all the therapist profiles from the current user site.
  static async getTherapists(signal) {
    return await this.api().get("profile/therapist", {
      dataKey: "result",
      signal,
    });
  }

  // Returns all the site manager profiles from the current user site.
  static async getSiteManagers(signal) {
    return await this.api().get("profile/sitemanager", {
      dataKey: "result",
      signal,
    });
  }

  static async post(newProfile) {
    return this.api().post(roleCreationMap[newProfile.roles[0]], newProfile, {
      dataKey: "result",
    });
  }

  static async remove(profile) {
    return this.api().delete(roleDeleteMap[profile.roles[0]] + profile.id, {
      delete: profile.userName,
      dataKey: "result",
    });
  }

  static async put(updatedProfile) {
    return this.api().put(
      roleEditMap[updatedProfile.roles[0]],
      updatedProfile,
      {
        dataKey: "result",
      }
    );
  }

  // Returns all patients for a given therapist id.
  static async getPatientCountByTherapistId(therapistId, signal) {
    return await this.api()
      .get(`profile/therapist/${therapistId}/patient/count`, {
        signal,
      })
      .then((result) => result.response.data.result);
  }

  static async transferAllPatientsFromTherapistToAnother(
    fromTherapistId,
    toTherapistId
  ) {
    return this.api().request({
      method: "post",
      url: `profile/transfer/patients-to-therapist/?fromTherapistId=${fromTherapistId}&toTherapistId=${toTherapistId}`,
    });
  }

  static async promote(profile) {
    return this.api().request({
      method: "post",
      url: `profile/sitemanager/promote/${profile.userName}`,
      dataKey: "result",
    });
  }

  static async setArchiveState(profile, archived) {
    return this.api().request({
      method: "put",
      url: `profile/patient/${profile.userName}/archive/${archived}`,
      save: "false",
    });
  }

  /*
   * Service methods extracted
   */

  static async addTherapist(therapist) {
    return this.parseSingleProfileFromRequest(
      this.api().post("account/therapist", therapist, {
        dataKey: "result",
      })
    );
  }

  static async addSiteManager(siteManager) {
    return this.parseSingleProfileFromRequest(
      this.api().post("account/sitemanager", siteManager, {
        dataKey: "result",
      })
    );
  }

  /**
   * Update a therapist
   * @param {object} data The therapist data
   */
  static async updateTherapist(therapist) {
    return this.parseSingleProfileFromRequest(
      this.api().put("profile/therapist", therapist, {
        dataKey: "result",
      })
    );
  }

  /**
   * Update a sitemanager
   * @param {object} data The sitemanager data
   */
  static async updateSiteManager(siteManager) {
    return this.parseSingleProfileFromRequest(
      this.api().put("profile/sitemanager", siteManager, {
        dataKey: "result",
      })
    );
  }

  /**
   * Update current user
   * @param {object} data the new data of the current user
   */
  static async updateSelf(profile) {
    return this.parseSingleProfileFromRequest(
      this.api().put("profile", profile, { dataKey: "result" })
    );
  }

  /**
   * Add a patient
   * @param {object} data The patient data
   */
  static async addPatient(profile, sendPasswordEmail = true) {
    return this.parseSingleProfileFromRequest(
      this.api().post(
        `account/patient?sendPasswordEmail=${sendPasswordEmail}`,
        profile,
        { dataKey: "result" }
      )
    );
  }

  /**
   * Update a patient
   * @param {object} data The patient data
   */
  static async updatePatient(profile) {
    return this.parseSingleProfileFromRequest(
      this.api().put("profile/patient", profile, { dataKey: "result" })
    );
  }

  /**
   * Get the userprofile from the username
   * @param {string} username The username
   */
  static async getProfileFromUsername(username, signal) {
    return await this.parseSingleProfileFromRequest(
      this.api().get(`profile/${username}`, {
        dataKey: "result",
        signal,
      })
    );
  }

  /**
   * Get therapist from patient username
   * @param {string} userName The patient username
   */
  static async getTherapistFromPatient(userName) {
    return this.parseSingleProfileFromRequest(
      this.api().get(`profile/patient/${userName}/therapist`, {
        dataKey: "result",
      })
    );
  }

  /**
   * Get the userprofile from a therapy by username
   * @param {string} userName The username
   */
  static async getTherapistProfileFromUserName(userName) {
    return this.parseSingleProfileFromRequest(
      this.api().get(`profile/therapist/${userName}`, {
        dataKey: "result",
      })
    );
  }

  /**
   * Set the access for a patient
   * @param {string} userName The username from the patient
   * @param {string} phoneNumber The new phoneNumber
   * @param {string} email The new email
   * @param {string} password The new password
   */
  static async addAccessToPatient(username, phoneNumber, email, password) {
    return this.parseSingleProfileFromRequest(
      this.api().post(
        `account/patient/add-access`,
        {
          userName: username,
          phoneNumber: phoneNumber,
          email: email,
          password: password,
        },
        {
          dataKey: "result",
        }
      )
    );
  }

  static async parseSingleProfileFromRequest(apiRequest) {
    return apiRequest.then((result) => {
      const profile = result.entities.profiles[0];
      return profile;
    });
  }

  isAdministrator() {
    return this.roles.includes(ADMIN_ROLE);
  }
  isPatient() {
    return this.roles.includes(PATIENT_ROLE);
  }
  isTherapist() {
    return this.roles.includes(THERAPIST_ROLE);
  }
  isDpo() {
    return this.roles.includes(DPO_ROLE);
  }
  isSiteManager() {
    return this.roles.includes(SITEMANAGER_ROLE);
  }
  isTherapistAdmin() {
    return this.isTherapist() && this.isSuperTherapist;
  }
  isMindMotionUser() {
    return this.isTherapist() || this.isSiteManager() || this.isPatient();
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    return [
      tidySearchTerm(this.fullName),
      this.patientSiteId,
      this.MMID,
      this.email,
      this.therapistId,
      this.userName,
    ];
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}

const roleCreationMap = {
  site: "account/sitemanager",
  therapist: "account/therapist",
  admin: "account/admin",
  dpoadmin: "account/dpoadmin",
};

const roleEditMap = {
  site: "profile/sitemanager/",
  therapist: "profile/therapist/",
  admin: "profile/admin/",
  dpoadmin: "profile/dpoadmin/",
};

const roleDeleteMap = {
  site: "account/sitemanager/",
  therapist: "account/therapist/",
  admin: "account/admin/",
  dpoadmin: "account/dpoadmin/",
  patient: "account/patient/",
};
