<template>
  <notifications position="top center" class="min-w-128">
    <template #body="{ item }">
      <warning-box :type="item.type" class="mt-3">
        <span>
          {{ item.text }}
        </span>
      </warning-box>
    </template>
  </notifications>
</template>

<script>
import WarningBox from "@/components/ui/WarningBox";

// @vuese
// component to display a toast
// @group 3. UI/Base
export default {
  components: { WarningBox },
};
</script>
