<template>
  <div class="border-blue-500 border-t-6 opacity-100 rounded p-8">
    <template v-if="messageHasTitle">
      <h2 v-if="options.html" class="dg-title" v-html="messageTitle"></h2>
      <h2 v-else class="dg-title">{{ messageTitle }}</h2>
    </template>
    <div v-if="options.html" class="dg-content mt-8" v-html="messageBody"></div>
    <div v-else class="dg-content mt-8">{{ messageBody }}</div>
    <br />

    <div
      class="flex flex-row mt-8"
      :class="options.showCancelText ? 'justify-between' : 'justify-end'"
    >
      <base-button
        @click="handleDismiss"
        v-if="options.showCancelText"
        class="btn-secondary me-2"
      >
        {{ options.cancelText }}
      </base-button>
      <base-button @click="handleProceed" class="btn-primary ms-2">
        {{ options.okText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import DialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js"; // Include mixin

// @vuese
// component that display a confirmation popup
// @group 3. UI/Base
export default {
  mixins: [DialogMixin],
  methods: {
    handleProceed() {
      this.proceed(); // included in DialogMixin
    },
    handleDismiss() {
      this.cancel(); // included in DialogMixin
    },
  },
};
</script>

<style>
/* Resets to use tailwind for controlling the styles we need */
.dg-backdrop {
  display: none;
}
.dg-main-content {
  padding: 0;
  max-width: 550px; /* modal-sm */
}
</style>
