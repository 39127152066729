<template>
  <div class="flex items-center break-words">
    <svg-icon v-if="icon" class="icon-2xl me-2" :name="icon" />
    <div :class="valueClasses">{{ localValue }}</div>
    <svg-icon
      v-if="tooltip"
      class="inline icon-sm -mt-1 mx-1 flex-shrink-0"
      name="info-circle"
      v-tooltip.bottom="tooltip"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    readOnlyClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasValue() {
      return this.value && this.value.length;
    },
    localValue() {
      return this.hasValue ? this.value : this.$t("Common.notSet");
    },
    valueClasses() {
      let classes = [];
      if (!this.hasValue) {
        classes.push("font-regular text-gray-300");
      } else if (!this.readOnlyClasses.length) {
        classes.push("font-semibold");
      } else {
        classes.push(this.readOnlyClasses);
      }
      return classes;
    },
  },
};
</script>
