import { Model } from "@vuex-orm/core";
import { userRootLocale } from "@/models/utils";
import Program from "@/models/Program.model";
import Store from "@/store";
import { parseISO } from "date-fns";
import { defaultLanguage } from "@/utils/locales";

export class ProgramPreset extends Model {
  static entity = "programpresets";

  static fields() {
    return {
      id: this.attr(null),
      program: this.attr(null, (value) => new Program(value)),
      metadata: this.attr(null, (value) => new ProgramPresetMetadata(value)),
      username: this.attr(""),
      createdBy: this.attr(""),
      modifiedBy: this.attr(""),
      createdAtUTC: this.attr(null),
      modifiedAtUTC: this.attr(null),
      positionIndex: this.attr(0),
    };
  }

  get ModifiedAtUTC() {
    return this.modifiedAtUTC ? parseISO(this.modifiedAtUTC) : null;
  }

  get IsTherapistPreset() {
    let currentUsername = Store.getters["Auth/username"];
    return this.username === currentUsername;
  }

  static fromConvertToPresetData(presetData) {
    let currentUsername = Store.getters["Auth/username"];
    let preset = {
      username: currentUsername,
      createdBy: currentUsername,
      modifiedBy: currentUsername,
      program: new Program(presetData.program),
      metadata: new ProgramPresetMetadata({}),
    };

    let language = userRootLocale() || defaultLanguage;
    preset.metadata.title[language] = presetData.title;
    preset.metadata.title[defaultLanguage] = presetData.title;
    preset.metadata.importedTitle[language] = presetData.title;
    preset.metadata.importedTitle[defaultLanguage] = presetData.title;
    preset.metadata.description[language] = presetData.description;
    preset.metadata.description[defaultLanguage] = presetData.description;

    return preset;
  }

  static fromProgramData(programData) {
    let presetData = {};
    presetData.username = Store.getters["Auth/username"];
    presetData.program = programData;
    presetData.metadata = {};
    presetData.title = programData.label;
    presetData.importedTitle = programData.label;
    presetData.description = programData.description;
    return ProgramPreset.fromConvertToPresetData(presetData);
  }

  /**
   * Get MindMotion presets
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getMindMotionPresets(signal) {
    return await this.api().get("/programpreset", {
      dataKey: "result",
      signal,
    });
  }

  /**
   * Get Therapist presets
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getTherapistPresets(signal) {
    return await this.api().get("/programpreset/therapist", {
      dataKey: "result",
      signal,
    });
  }

  static createTherapistPreset(programPreset) {
    return this.api().post("/programpreset/custom", programPreset, {
      dataKey: "result",
    });
  }

  static updateTherapistPreset(programPreset) {
    return this.api().put(`/programpreset/${programPreset.id}`, programPreset, {
      dataKey: "result",
    });
  }

  /**
   * Delete a preset from the database
   * @param programPresetId
   * @returns {*}
   */
  static deleteTherapistPreset(programPresetId) {
    return this.api().delete(`/programpreset/id/${programPresetId}`, {
      dataKey: "result",
      // Also delete from the store
      // See: https://vuex-orm.github.io/plugin-axios/guide/usage.html#delete-requests
      delete: programPresetId,
    });
  }
}

// This data is only valid for MindMotion™ presets
export class ProgramPresetMetadata {
  constructor(programPresetMetaData) {
    this.title = programPresetMetaData.title || {};
    this.description = programPresetMetaData.description || {};
    this.severity =
      programPresetMetaData.severity || this.defaultSeverityOptions();
    this.function =
      programPresetMetaData.function || this.defaultFunctionOptions();
    this.importedTitle = programPresetMetaData.importedTitle || {};
  }

  defaultSeverityOptions() {
    return {
      key: "",
      text: {},
    };
  }

  defaultFunctionOptions() {
    return {
      key: "",
      text: {},
    };
  }

  get Title() {
    if (!this.title[userRootLocale()]) {
      return this.title[defaultLanguage];
    }
    return this.title[userRootLocale()];
  }

  set Title(title) {
    this.title = {};
    this.title[userRootLocale()] = title;
    this.title[defaultLanguage] = title;
  }

  get Description() {
    if (!this.description[userRootLocale()]) {
      return this.description[defaultLanguage];
    }
    return this.description[userRootLocale()];
  }

  set Description(description) {
    this.description = {};
    this.description[userRootLocale()] = description;
    this.description[defaultLanguage] = description;
  }

  get Severity() {
    return {
      key: this.severity.key,
      text: this.severity.text[userRootLocale()],
    };
  }

  get Function() {
    return {
      key: this.function.key,
      text: this.function.text ? this.function.text[userRootLocale()] : "",
    };
  }

  get ImportedTitle() {
    if (!this.importedTitle[userRootLocale()]) {
      return this.importedTitle[defaultLanguage];
    }
    return this.importedTitle[userRootLocale()];
  }

  set ImportedTitle(importedTitle) {
    this.importedTitle[userRootLocale()] = importedTitle;
    this.importedTitle[defaultLanguage] = importedTitle;
  }
}
