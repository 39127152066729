<!--
 Global application layout
 Only elements available in every context should be placed here.
 This layout should be used only once on top of the components.
-->
<template>
  <div id="app" class="h-full min-h-screen overflow-y-auto">
    <!-- Global modals -->
    <toast />
    <login-modal />

    <stack>
      <slot />
    </stack>

    <!-- Cookie bottom banner -->
    <vue-cookie-law
      theme="mm"
      storageType="localStorage"
      :storageName="cookiesAcceptedName"
      :buttonText="$t('App.CookieBar.cookieButtonText')"
      :message="$t('App.CookieBar.cookieMessage')"
      :accept="saveAuthDataInCookiesIfAvailable"
    />
    <!-- Permits modal to be placed globally here -->
    <portal-target name="modal" tag="div" />
  </div>
</template>

<script>
// Components
import Toast from "@/components/ui/Toast";
import LoginModal from "@/components/login/LoginModal";
import Stack from "@/components/layout/common/Stack";
import VueCookieLaw from "vue-cookie-law";
// Libraries, services
import { mapGetters } from "vuex";
import { cookiesAcceptedName, setAuthTokenCookie } from "@/utils/cookies";
import { setDeviceId, setRefreshToken } from "@/utils/localStorage";

export default {
  components: { Stack, LoginModal, Toast, VueCookieLaw },
  data() {
    return {
      // Cookie accepted bar
      cookiesAcceptedName: cookiesAcceptedName,
    };
  },
  computed: {
    ...mapGetters("Auth", ["token", "refreshToken", "deviceId"]),
  },
  methods: {
    // N.B.: The cookie bar accept event doesn't work,
    // but I leave the correct behavior here for when it gets fixed later on.
    saveAuthDataInCookiesIfAvailable() {
      // Only store a usable set of data
      if (this.token && this.refreshToken && this.deviceId) {
        setAuthTokenCookie(this.token);
        setRefreshToken(this.refreshToken);
        setDeviceId(this.deviceId);
      }
    },
  },
};
</script>

<style lang="postcss">
.Cookie--mm {
  @apply p-8;
  @apply bg-gray-100;

  & .Cookie__button {
    @apply text-white bg-blue-500;

    &:hover {
      @apply text-white bg-blue-750;
    }
  }
}
</style>
