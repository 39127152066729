import Cookies from "js-cookie";

// Accept cookies functions
export const cookiesAcceptedName = "cookie:accepted";

export function areCookiesAccepted() {
  return localStorage.getItem(cookiesAcceptedName);
}

/// Authentication cookie functions
const tokenCookieName = "mm_token";

// See: https://developer.mozilla.org/fr/docs/Web/HTTP/Headers/Set-Cookie/SameSite
// We flag our cookies as secure and permit their use in third party apps.
export function setAuthTokenCookie(token) {
  Cookies.set(tokenCookieName, token, { sameSite: "Strict", secure: true });
}

export function getAuthTokenCookie() {
  return Cookies.get(tokenCookieName);
}

export function removeAuthTokenCookie() {
  Cookies.remove(tokenCookieName, { sameSite: "Strict", secure: true });
}

// Telemetry cookies
export function setAppInsightCookie(name, value) {
  Cookies.set(name, value, { sameSite: "Strict", secure: true });
}

export function getAppInsightCookie(name) {
  return Cookies.get(name);
}

export function removeAppInsightCookie(name) {
  Cookies.remove(name, { sameSite: "Strict", secure: true });
}
