/**
 * Format the unformatted mmid
 * @param {string} value The unformatted mmid
 */
function formatToMMIDWithDashes(value) {
  if (!value) {
    return "";
  }
  return "MMID-" + value.replace(/(\w{2})(\w{2})(\w{2})/, "$1-$2-$3");
}

/**
 * Generates a random uuid
 */
function generateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

/**
 * Build the full name from first and lastname
 * @param {string | null} firstName The firstname
 * @param {string | null} lastName The lastname
 * @return {string} The full name under the form 'firstName lastName'
 * or one of the 2 if only one is a valid string
 */
function buildNameFromFirstAndLastName(
  firstName,
  lastName,
  isHmtlMode = false
) {
  firstName = firstName?.toString()?.trim();
  lastName = lastName?.toString()?.trim();
  if (!firstName?.length && !lastName?.length) {
    return isHmtlMode ? "---" : "";
  }
  if (!firstName?.length && lastName?.length) {
    return isHmtlMode ? "<b>" + lastName + "</b>" : lastName;
  }
  if (firstName?.length && !lastName?.length) {
    return firstName;
  }
  return isHmtlMode
    ? firstName + " <b>" + lastName + "</b>"
    : firstName + " " + lastName;
}

export { formatToMMIDWithDashes, generateUUID, buildNameFromFirstAndLastName };
