<template>
  <div class="flex items-center">
    <span class="text-h3 title-ellipsis">
      {{ title }}
    </span>
    <svg-icon
      v-if="title && subTitle.length"
      name="chevron-right"
      class="icon-md mx-3 flip-icon-if-rtl"
    />
    <span v-if="title && subTitle.length" class="text-h3 truncate">
      {{ subTitle }}
    </span>
    <new-feature-badge v-if="showNewBadge" class="ms-4" />
  </div>
</template>

<script>
import NewFeatureBadge from "@/components/ui/NewFeatureBadge";

export default {
  components: { NewFeatureBadge },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    showNewBadge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.title-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
