import Vue from "vue";
import SvgIcon from "@/components/ui/SvgIcon";
import BaseButton from "@/components/ui/BaseButton";
import LoadingLayout from "@/components/ui/LoadingLayout";
import Breadcrumbs from "@/components/application/Breadcrumbs";

Vue.component("svg-icon", SvgIcon);
Vue.component("base-button", BaseButton);
Vue.component("breadcrumbs", Breadcrumbs);
Vue.component("loading-layout", LoadingLayout);
