import { Model } from "@vuex-orm/core";

export const therapeuticGoalMaxLength = 1000;

export default class TherapeuticGoal extends Model {
  static entity = "therapeuticGoal";
  static primaryKey = "patientUsername";

  static fields() {
    return {
      therapistUsername: this.attr(null),
      patientUsername: this.attr(null),
      goal: this.attr(null),
    };
  }

  /* Returns therapeutic goal as object from the store or fetch it.
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchMyTherapeuticGoal(signal) {
    return await this.api()
      .get("therapeutic-goal", {
        dataKey: "result",
        signal,
      })
      .then(() => {
        return TherapeuticGoal.query().first()?.goal ?? "";
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *   Fetch therapeutic goal from companion for a given patient.
   *   @param {string} username The username
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchTherapeuticGoalForPatient(username, signal) {
    return await this.api()
      .get(`therapeutic-goal/patient/${username}`, {
        dataKey: "result",
        signal,
      })
      .then((response) => {
        return TherapeuticGoal.query().find(username)?.goal ?? "";
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *   Returns the therapeutic goal for the given patient from the store or fetch it from companion.
   *   @param {string} username The username
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async getTherapeuticGoalForPatient(username, signal) {
    let tg = TherapeuticGoal.query().find(username);
    if (tg) {
      return tg.goal;
    } else {
      return await this.fetchTherapeuticGoalForPatient(username, signal);
    }
  }

  /**
   *   adds a new therapeutic goal for a patient.
   *   @param {string} username corresponding to the patient username
   *   @param {string} therapeuticGoalValue Therapeutic goal to add to the patient
   */
  static async addTherapeuticGoalForPatient(username, therapeuticGoalValue) {
    let newTherapeuticGoal = new TherapeuticGoal();
    newTherapeuticGoal.patientUsername = username;
    newTherapeuticGoal.goal = therapeuticGoalValue;
    return await this.postTherapeuticGoalForPatient(newTherapeuticGoal);
  }

  /**
   *   adds a new therapeutic goal for a patient.
   *   @param {TherapeuticGoal} newTherapeuticGoal The therapeutic goal to add
   */
  static async postTherapeuticGoalForPatient(newTherapeuticGoal) {
    return this.api()
      .post("therapeutic-goal", newTherapeuticGoal, {
        dataKey: "result",
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
